<template>
  <!-- より明るい背景色と縁を丸めたコンテナを作成 -->
  <div class="p-6 bg-white shadow-xl rounded-lg">
    <!-- タイトルを追加 -->
    <div class="text-2xl font-semibold mb-4 ml-40">PlayLine Select</div>
    <!-- Positions & Actions -->
    <div class="flex justify-start space-x-12 mb-6 ml-40">
      <div v-for="(position, index) in positions" :key="position" class="text-center relative">
        <div 
          @click="resetSelection(index)"
          class="bg-blue-600 text-white p-3 rounded-lg cursor-pointer hover:bg-blue-700 transition"
        >
          {{ position }}
        </div>

        <select 
          v-model="selectedActions[index]" 
          :disabled="index > getCurrentIndex"
          :class="{
              'bg-white': true,
              'opacity-50 cursor-not-allowed': index > getCurrentIndex
          }"
          class="mt-2 w-32 block appearance-none bg-white border border-gray-300 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:border-blue-500"
        >
          <option v-if="index > getCurrentIndex" disabled>Select</option>
          <option v-if="index === 0">Raise 2.5BB</option>
          <option v-if="index === 1">Raise 10BB</option>
          <option v-if="index === 2">Raise 22BB</option>
          <option v-if="index === 3">Raise 100BB</option>
          <option v-if="index === 4">Call</option>
        </select>

        <!-- 矢印の配置の修正 -->
        <svg 
        v-if="index !== positions.length - 1" 
        class="h-6 w-6 mx-auto mt-2 mb-2 absolute right-[-35px] top-[50%] transform -translate-y-[50%] text-gray-400"
        fill="none" 
        viewBox="0 0 24 24" 
        stroke="currentColor" 
        xmlns="http://www.w3.org/2000/svg"
        >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
        </svg>
      </div>
    </div>

    <!-- Play Line: Style Updated -->
    <div class="mt-5 text-center mb-6 text-xl font-semibold border-t pt-4 border-gray-200">
    </div>
    <!-- Results Section -->
    <div class="results-section flex flex-col mb-6">

        <!-- Hero and Villain Range in Flex -->
        <div class="ranges flex justify-between">

            <!-- Invisible Spacer to push Hero Range to the right -->
            <div class="flex-grow"></div>

            <!-- Hero Range -->
            <div class="hero-range mb-4 w-5/12">
                  <h3 class="text-xl font-bold mb-3 inline-block">Hero:</h3>
                  <span class="text-lg ml-2 text-orange-600 font-bold">{{ heroStatus }}</span> <!-- 文字色と太さを変更 -->
            <div v-for="row in 13" :key="'row-hero-' + row" class="flex">
                <div 
                  v-for="col in 13" 
                  :key="'cell-hero-' + row + '-' + col" 
                  class="cell relative border border-gray-300 flex items-center justify-center bg-transparent"
                  :style="getResultCellBackgroundColor(row, col)" 
                >
                    <span class="absolute top-0 left-0 text-xs font-bold">
                    {{ getCardCombination(row, col) }}
                    </span>
                </div>
            </div>
            
            </div>

            <!-- Villain Range -->
            <div class="villain-range mb-4 w-6/12">
                  <h3 class="text-xl font-bold mb-3 inline-block">Villain:</h3>
                  <span class="text-lg ml-2 text-orange-600 font-bold">{{ villainStatus }}</span> <!-- 文字色と太さを変更 -->
            <div v-for="row in 13" :key="'row-villain-' + row" class="flex">
                <div 
                    v-for="col in 13" 
                    :key="'cell-villain-' + row + '-' + col" 
                    class="cell relative border border-gray-300 flex items-center justify-center bg-transparent"
                    :style="getResultCellBackgroundColor(row, col, 'villain')"
                >
                    <span class="absolute top-0 left-0 text-xs font-bold">
                    {{ getCardCombination(row, col) }}
                    </span>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div> <!-- この閉じタグが必要です -->
</template>

<script>
import { playLines } from '@/data/PreflopSolutionHU.js';

export default {
  data() {
      return {
        matrix: [],
        positions: ['BTN', 'BB', 'BTN', 'BB', 'BTN'],
        selectedActions: ['','','','',''],
        playline: '',
        parsedPlayLines: {},
        villainParsedPlayLines: {}
    };
  },
  created() {
      this.loadPlayLine();
  },
  watch: {
    selectedActions: {
      deep: true,
      handler() {
        this.loadPlayLine();
      }
    }
  },
  computed: {
    getCurrentIndex() {
      return this.selectedActions.findIndex(action => !action);
    },
    heroStatus() {
      const lastSelectedAction = [...this.selectedActions].reverse().find(action => action);
      switch(lastSelectedAction) {
        case "Raise 2.5BB":
          return "vs Raise 2.5BB";
        case "Raise 10BB":
          return "vs Raise 10BB";
        case "Raise 22BB":
          return "vs Raise 22BB";
        case "Raise 100BB":
          return "vs Raise 100BB";
        default:
          return "RFI";
      }
    },

    villainStatus() {
      const lastSelectedAction = [...this.selectedActions].reverse().find(action => action);
      switch(lastSelectedAction) {
        case "Raise 2.5BB":
          return "Raise 2.5BB";
        case "Raise 10BB":
          return "Raise 10BB";
        case "Raise 22BB":
          return "Raise 22BB";
        case "Raise 100BB":
          return "Raise 100BB";
        default:
          return "";
      }
    }
  },
  methods: {
      loadPlayLine() {
          const lastSelectedAction = [...this.selectedActions].reverse().find(action => action);
          switch (lastSelectedAction) {
              case "Raise 2.5BB":
                  this.parsedPlayLines = this.parsePlayLines(playLines.playLine2);
                  this.villainParsedPlayLines = this.parsePlayLines(playLines.playLine1);
                  break;
              case "Raise 10BB":
                  this.parsedPlayLines = this.parsePlayLines(playLines.playLine3);
                  this.villainParsedPlayLines = this.parsePlayLines(playLines.playLine2);
                  break;
              case "Raise 22BB":
                  this.parsedPlayLines = this.parsePlayLines(playLines.playLine4);
                  this.villainParsedPlayLines = this.parsePlayLines(playLines.playLine3);
                  break;
              case "Raise 100BB":
                  this.parsedPlayLines = this.parsePlayLines(playLines.playLine5);
                  this.villainParsedPlayLines = this.parsePlayLines(playLines.playLine4);
                  break;
              default:
                  this.parsedPlayLines = this.parsePlayLines(playLines.playLine1);
                  this.villainParsedPlayLines = {}; // HeroがPlayLine1の場合、Villainは色塗りしない
          }
      },
    parsePlayLines(rawData) {
        let parsedData = {};
        for (let action in rawData) {
            if (typeof rawData[action] === "string") {
                let combinations = rawData[action].split(',');
                for (let combo of combinations) {
                    let [cards, frequency] = combo.split(':');
                    let cardList = cards.split(',');
                    for (let card of cardList) {
                        if (!parsedData[action]) {
                          parsedData[action] = {};
                        }
                        if (card.length === 2 && card[0] !== card[1]) {
                            parsedData[action][card + 'o'] = frequency ? parseFloat(frequency) : 1;
                            parsedData[action][card + 's'] = frequency ? parseFloat(frequency) : 1;
                        } else {
                            parsedData[action][card] = frequency ? parseFloat(frequency) : 1;
                        }
                    }
                }
            } else {
                parsedData[action] = {}; // 空のオブジェクトを代入
            }
        }
        return parsedData;
    },

    getCardCombination(row, col) {
        const cards = ['A', 'K', 'Q', 'J', 'T', '9', '8', '7', '6', '5', '4', '3', '2'];
        if (row === col) {
            return cards[row - 1] + cards[col - 1];
        } else if (row < col) {
            return cards[row - 1] + cards[col - 1] + 's';
        } else {
            return cards[col - 1] + cards[row - 1] + 'o';
        }
    },
    getResultCellBackgroundColor(row, col, player = 'hero') {
        const combination = this.getCardCombination(row, col);
        let dataToUse = player === 'hero' ? this.parsedPlayLines : this.villainParsedPlayLines;
        let totalFrequency = 0;
        let gradientData = [];
        const actionColors = {
            "Raise": "rgba(255, 50, 50, 0.8)",
            "Call": "rgba(50, 255, 50, 0.7)",
            "Fold": "rgba(50, 50, 255, 0.7)"
        };

        // HeroがPlayLine1の場合、Villainの背景色を設定しない
        if (player === 'villain' && Object.keys(dataToUse).length === 0) {
            return {};
        }

        for (let action in actionColors) {
            if (dataToUse[action] && dataToUse[action][combination]) {
                const frequency = dataToUse[action][combination];
                totalFrequency += frequency;  // 頻度を加算
                gradientData.push({ action: action, color: actionColors[action], frequency });
            }
        }

        // RaiseとCallの合計の頻度が100%未満の場合、その差分をFoldとして扱う
        if (totalFrequency < 1 && !gradientData.some(data => data.action === "Fold")) {  // Foldがすでに存在しない場合のみ追加
            gradientData.push({
                action: "Fold",
                color: actionColors["Fold"],
                frequency: 1 - totalFrequency
            });
        }

        gradientData.sort((a, b) => {
            const sortOrder = ["Raise", "Call", "Fold"];
            return sortOrder.indexOf(a.action) - sortOrder.indexOf(b.action);
        });

        let gradient = [];
        let accumulatedFrequency = 0;

        gradientData.forEach(data => {
            let start = accumulatedFrequency * 100;
            accumulatedFrequency += data.frequency;
            let end = accumulatedFrequency * 100;
            gradient.push(`${data.color} ${start}%, ${data.color} ${end}%`);
        });

        return {
            background: `linear-gradient(90deg, ${gradient.join(', ')}, transparent ${accumulatedFrequency * 100}%)`
        };
    },
    resetSelection(index) {
        this.selectedActions = this.selectedActions.map((action, i) => (i > index ? '' : action));
        this.loadPlayLine();
    }
  }
};
</script>

<style>
/* こちらにはTailwindCSS以外のスタイルを記述してください。 */
</style>