<template>
  <div class="prose prose-lg mx-auto">
    <h1>Vue.jsの次世代機能</h1>
    <p>
      Vue 3は、Vue 2からの大きなステップアップとして、多くの新機能と改善点を持っています。Composition APIやTeleportなどの新機能が追加され、さらに性能も向上しています。
    </p>
    <p>Vue 3の主な特徴には、Composition API、複数のルート要素、Teleport、Suspenseなどが含まれています。これにより、より複雑なアプリケーションを簡単に構築できるようになりました。</p>
  </div>
</template>

<script>
export default {
  name: "ArticleSample1"
};
</script>

<style scoped>
/* 必要に応じてスタイルを追加 */
</style>
