<template>
  <div class="prose-container">
    <h1 class="heading">Range Simplifierとは？</h1>
    <p class="mb-4">
      最近はGTO WizardやGTO Baseなどのように計算済みのGTO戦略をブラウザから閲覧するサービスの台頭により、プリフロップソリューションを使うことはかなり一般的になりました。
    </p>
    <p>
      プリフロップソリューションを使う上での一つの難しい点として混合戦略が含まれていることが挙げられます。アクションが一つに定まらず、複数の戦略が頻度によって混在する戦略です。いい加減にソリューションを覚えたり、実戦したりすると頻度が守られず偏ったアクションになることが起こります。その代表的な例がマイクロステークスやライブキャッシュなどのBBからの3Betだと考えます。
    </p>
    <p>
      下図のBTNオープンに対するBBの3Betのレンジをご覧頂ければ分かるのですが、BTNのオープンレンジにEquityが高いとは決して言えない、K~Txのスーテッドなどによる3Betがそれぞれのハンドで中~低頻度で行われます。経験上、実戦でキチンと学習しているレギュラー以外から、これらのハンドの3Betが飛んでくることはほとんどないです。
    </p>
    <div class="image-container">
      <img src="/Article1/BB_3BetvsBTN_Open.png" alt="BB 3Bet vs BTN Open" class="centered-image"/>
      <p class="image-title">BB 3Bet vs BTN Open</p>
    </div>
    <p>
      ソリューションをお使いの方でもこのあたりを適当にプレイされている方は多いのではないかと想像します。多くのプレイヤーが混合戦略になっている部分をオーバーフォールドしたり、レイズする頻度が低かったりするリークがあるので、シンプルなプリフロップレンジチャートにすることで、そういったリークを減すツールです。
    </p>
    <p>ちなみにGTO WizardやGTO BaseからコピーできるレンジデータはPioSOLVER形式になっています。（GTO Wizardはサブスク会員のみコピー可能）</p>


    <h1 class="heading">使い方</h1>
    <div class="image-container">
      <img src="/Article1/Range Simplifier Image.png" alt="Range Simplifier" class="centered-image"/>
      <p class="image-title">Range Simplifier</p>
    </div>
    <div class="boxed-list">
    <ul class="list-disc pl-5 mb-4">
      <li>Range Settingに丸めたいレンジのPioSOLVER形式のテキストデータを入力、必要に応じてProcess Nameを設定（またPreviewでレンジを確認することが可能）</li>
      <li>3種類のRound、Clusterの計4つのいずれかのボタンを押下するとResultに丸められたレンジが表示</li>
      <li>Processed Resultに一覧表示されていくので、選択すると以前の加工結果を再表示可能。またExport as Imageを押下するとPNGファイルとして出力可能（ファイル名はRange Setting時のProcess Name）</li>
    </ul>
    </div>

    <h2 class="heading">RoundとCluster</h2>
    <p>25%,50%,100%の3種類のRound、Clusterの計4つの加工方法を用意しました。</p>
    <p>Roundはそれぞれの割合毎に切りよく頻度が丸められます。Clusterは混合戦略になっている部分を合計し、頻度の高い部分から分配して純粋戦略にする加工となります。</p>

    <h2 class="heading">サンプルデータ</h2>
    <h3>Raiseデータ:</h3>
    <div class="data-box">
      <textarea readonly class="data-textarea1">AA,KK,QQ,JJ,TT,99:0.92,88:0.42,77:0.32,66:0.15,55:0.08,AK,AQs,AQo:0.94,AJs,AJo:0.54,ATs:0.86,ATo:0.2,A9o:0.35,A8o:0.19,A7o:0.31,A5s,A5o:0.2,A4s:0.9,A3s:0.38,KQs,KQo:0.48,KJs,KJo:0.29,KTs:0.2,KTo:0.54,K9s:0.13,K9o:0.49,K7s:0.31,K6s:0.29,K5s:0.02,K4s:0.02,K3s:0.15,QJo:0.36,QTs:0.06,QTo:0.54,Q9o:0.16,Q8s:0.34,Q7s:0.22,Q6s:0.33,Q5s:0.15,Q4s:0.14,Q3s:0.16,JTs:0.55,JTo:0.45,J9s:0.55,J9o:0.36,J8s:0.32,J7s:0.59,J6s:0.34,J5s:0.21,J4s:0.16,T9s:0.98,T9o:0.2,T8s:0.46,T7s:0.28,T6s:0.51,98s:0.48,87s:0.42,76s:0.3,65s:0.46,54s:0.39</textarea>
      <button class="copy-button" @click="copyToClipboard('.data-textarea1')">copy range</button>
    </div>

    <h3>Callデータ:</h3>
    <div class="data-box">
      <textarea readonly class="data-textarea2">99:0.08,88:0.58,77:0.68,66:0.85,55:0.92,44,33,22,AQo:0.06,AJo:0.46,ATs:0.14,ATo:0.8,A9s,A9o:0.65,A8s,A8o:0.81,A7s,A7o:0.69,A6s,A5o:0.8,A4s:0.1,A4o:0.24,A3s:0.62,A2s,KQo:0.52,KJo:0.7,KTs:0.8,KTo:0.46,K9s:0.87,K9o:0.51,K8s,K7s:0.69,K6s:0.71,K5s:0.98,K4s:0.98,K3s:0.85,K2s,QJs,QJo:0.64,QTs:0.94,QTo:0.46,Q9s,Q9o:0.46,Q8s:0.66,Q7s:0.78,Q6s:0.67,Q5s:0.85,Q4s:0.86,Q3s:0.84,Q2s,JTs:0.45,JTo:0.55,J9s:0.45,J9o:0.64,J8s:0.68,J7s:0.41,J6s:0.66,J5s:0.79,J4s:0.84,J3s:0.66,T9s:0.02,T9o:0.8,T8s:0.54,T8o:0.2,T7s:0.72,T6s:0.49,T5s:0.08,98s:0.52,98o:0.19,97s,96s,87s:0.58,87o:0.16,86s,85s,76s:0.7,75s,74s,65s:0.54,64s,63s:0.76,54s:0.61,53s,52s:0.42,43s</textarea>
      <button class="copy-button" @click="copyToClipboard('.data-textarea2')">copy range</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArticleSample1",
  methods: {
    copyToClipboard(elementSelector) {
      const textArea = this.$el.querySelector(elementSelector);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
    }
  }
};
</script>

<style scoped>
.prose-container {
  max-width: 1200px;
  margin: 0 auto;
  padding:6px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}

h1 {
  font-size: 2rem;
  padding: 8px 12px;
  font-weight: 700;
  background-color: #007BFF;
  color: #ffffff;
  margin-top: 0px;
  margin-bottom: 24px;
  border-radius: 8px;
}

h2 {
  font-size: 1.8rem;
  padding: 6px 12px;
  font-weight: 700;
  background-color: #4A4A4A;
  color: #ffffff;
  margin-top: 0px;
  margin-bottom: 24px;
  border-radius: 8px;
  border-bottom: none !important; 
  text-decoration: none !important;
}

h1, h2, h3 {
    margin-top: 2em;  /* 見出しの上にスペースを追加 */
    margin-bottom: 1em;  /* 見出しの下にスペースを追加 */
}

p, ul {
  font-size: 1.2rem;
  line-height: 1.7;
  margin-bottom: 24px;
}

ul {
  list-style-type: disc;
  margin-top: 20px;
  margin-bottom: 10px;
}

ul li {
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: 500;
}

.boxed-list {
    background-color: #f5e8d7;
    padding: 40px;
    border-radius: 20px;  /* 角をもっと丸く */
    position: relative;
    width: 1000px;  /* 横幅を小さくする */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* 影を付けて浮き出る感じに */
    border: 2px solid #e4a57d;  /* 薄いオレンジ色のボーダー */
    margin-left: auto;
    margin-right: auto;
}

.boxed-list:before {
    content: "★";
    font-size: 24px;
    position: absolute;
    top: -15px;  /* ボックスの外に配置 */
    left: 50%;  /* センタリングの基準点を中心に */
    transform: translateX(-50%);  /* 実際のセンタリング実行 */
    color: #e4a57d;
    background-color: #fff;  /* 背景を白に */
    padding: 5px;  /* 星の周りにスペースをとる */
    border-radius: 50%;  /* 円形に */
    border: 4px solid #e4a57d;  /* 星の外側のボーダー */
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.centered-image {
  width: 66.67%; /* 2/3 of the container's width */
  margin: 0 auto;
}

.image-title {
  text-align: center;
  margin-top: 10px;
}

.data-box {
  position: relative;
  margin-bottom: 24px;
}

.data-textarea1,.data-textarea2 {
  width: 90%;
  height: 150px; /* or whatever height you prefer */
  overflow-y: scroll;
  resize: none;
  font-size: 1.2rem;
}

.copy-button {
  position: absolute;
  top: 50%;
  right: 1%;
  transform: translateY(-50%);
  background-color: #6B7280;
  color: #ffffff;
  padding: 8px 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

</style>