<template>
  <div id="main-container">
    <div id="drop-zone" @dragover.prevent @drop="handleDrop" @click="triggerFileInput">
      <div class="drag-area">
        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" style="display: none" />
        <label for="file" class="file-label">Click here or drop a file to upload.</label>
        <!-- Space for displaying the name of the uploaded file -->
        <p class="file-name">{{ fileName }}</p>
      </div>
      <div class="process-button-wrapper">
        <button class="process-button" v-on:click.stop.prevent="submitFile()">Process</button>
      </div>
      <!-- Space for displaying error message -->
      <div class="error-message" v-bind:class="{ 'error-message-visible': showError }">{{ errorMessage }}</div>
    </div>
    <div id="bet-sizes-container" class="p-2 space-y-1 bg-white rounded-lg shadow-md">
      <h2 class="mb-1 text-lg font-semibold">Bet Sizes</h2>
      <div v-for="(betSize, index) in betSizes" :key="index" class="flex items-center space-x-2">
        <label :for="'betSize' + index" class="w-20 text-right">Bet Size {{ index + 1 }}</label>
        <input type="text" v-model="betSizes[index]" :id="'betSize' + index" class="w-16 border-2 border-gray-300 rounded-md">
        <span>%</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      file: '',
      fileName: 'No file uploaded',
      errorMessage: '',
      showError: false,
      betSizes: ['', '', '', '', '']  // Add betSizes to data
    }
  },
  methods: {
    handleDrop(e) {
      e.preventDefault();
      this.file = e.dataTransfer.files[0];
      this.fileName = 'Uploaded file: ' + this.file.name;
    },
    submitFile() {
    // Reset error message and error visibility
    this.errorMessage = '';
    this.showError = false;

    // Check if a file has been uploaded
    if (!this.file) {
      this.errorMessage = 'an incorrect csv file';
      this.showError = true;
      return;
    }

    let formData = new FormData();
    formData.append('file', this.file);
    for (const betSize of this.betSizes) {
      if (isNaN(betSize) || betSize < 0 || betSize > 100) {
        this.errorMessage = 'Bet sizes must be numbers between 0 and 2000.';
        this.showError = true;
        return;
      }
      if (betSize !== '') {
        formData.append('betSizes', betSize);
      }
    }

    const backendUrl = 'https://poker-sandbox-backend-7fcd53c6ff83.herokuapp.com';

    axios.post(`${backendUrl}/process-csv/AggregatedReportPrep`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'blob' // for file download
        }
    ).then(response => {
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.csv');
        document.body.appendChild(link);
        link.click();
    }).catch(error => {
      // Update error message
      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          error.response.data.text().then(text => {
              const data = JSON.parse(text);
              this.errorMessage = data.error;
          });
      } else if (error.request) {
          // The request was made but no response was received
          this.errorMessage = 'No response from server';
      } else {
          // Something happened in setting up the request that triggered an Error
          this.errorMessage = error.message;
      }
      this.showError = true;

      // Reset file and filename
      this.file = '';
      this.fileName = 'No file uploaded';
    });

    // Reset file and filename
    this.file = '';
    this.fileName = 'No file uploaded';
  },
    triggerFileInput() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      
      // ファイルサイズのバリデーション
      if (this.file.size > 5 * 1024 * 1024) {
        this.errorMessage = 'File size exceeds 5MB';
        this.showError = true;
        return;
      }
      
      // ファイルタイプのバリデーション（例: CSVファイルのみ許可）
      if (this.file.type !== 'text/csv') {
        this.errorMessage = 'Only CSV files are allowed';
        this.showError = true;
        return;
      }
      
      this.fileName = 'Uploaded file: ' + this.file.name;

      // Clear the file input value
      this.$refs.file.value = null;
    }
  }
}
</script>

<style scoped>
#main-container {
  display: flex;
  justify-content: flex-start;
  gap: 50px; /* adjust this value as needed */
}

#bet-sizes-container {
  width: 250px; /* adjust this value as needed */
}

#drop-zone {
  width: 300px;
  height: 200px;
  border: 2px dashed #aaa;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.drag-area {
  margin-top: 60px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.process-button-wrapper {
  margin-top: 0px; /* adjust this value as needed */
}

.process-button {
  margin-top: 0px; 
  background-color: #0275d8;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.error-message {
  margin-top: 10px; 
  color: transparent;
  height: 40px; /* adjust this value as needed */
  line-height: 0px; /* adjust this value as needed */
  text-align: center;
}

.error-message-visible {
  color: red;
}

.file-name {
  height: 40px; /* adjust this value as needed */
  line-height: 40px; /* adjust this value as needed */
  margin-top: 10px;
}

</style>