<template>
  <div id="main-container">
    <div id="upload-and-bet-size">
      <div id="drop-zone" @dragover.prevent @drop="handleDrop" @click="triggerFileInput">
        <div class="drag-area">
          <input type="file" id="file" ref="file" v-on:change="handleFileUpload($event)" style="display: none" />
          <label for="file" class="file-label">Click here or drop a file to upload.</label>
          <p class="file-name">{{ fileName }}</p>
        </div>
        <div class="process-button-wrapper">
          <button class="process-button" v-on:click.stop.prevent="submitFile()">Process</button>
        </div>
        <div class="error-message" v-bind:class="{ 'error-message-visible': showError }">{{ errorMessage }}</div>
      </div>
      <div id="bet-sizes-container" class="p-2 space-y-1 bg-white rounded-lg shadow-md">
        <h2 class="mb-1 text-lg font-semibold">Bet Sizes</h2>
        <div v-for="(betSize, index) in betSizes" :key="index" class="flex items-center space-x-2">
          <label :for="'betSize' + index" class="w-20 text-right">Bet Size {{ index + 1 }}</label>
          <input type="text" v-model="betSizes[index].size" :id="'betSize' + index" class="w-16 border-2 border-gray-300 rounded-md">
          <span>%</span>
        </div>
      </div>
    </div>
     <div id="table-container" class="p-2 space-y-1 bg-white rounded-lg shadow-md">
        <h2 class="mb-1 text-lg font-semibold">Processed Data</h2>
        <div class="flex flex-col w-full border-l border-gray-500 overflow-x-auto">
          <div class="flex shrink-0 h-12 border-b border-gray-500">
            <div class="flex h-full px-4 items-center text-lg font-semibold">
              Summary
            </div>
          </div>
          <div ref="tableDiv" class="flex-grow overflow-y-scroll will-change-scroll">
            <table v-if="responseData.length" class="w-full h-full text-sm text-center align-middle">
            <thead class="sticky top-0 z-30 bg-gray-100 shadow">
              <tr style="height: calc(1.9rem + 1px)">
                <th @click="sortBy('Flop')">Flop <button>▲</button></th>
                <th @click="sortBy('OOP Equity')">OOP Equity <button>▲</button></th>
                <th @click="sortBy('IP Equity')">IP Equity <button>▲</button></th>
                <th v-for="(betSize, index) in filteredBetSizes" :key="index" @click="sortBy(`Bet ${betSize.size}% freq`)">Bet {{ betSize.size }}% freq <button>▲</button></th>
                <th v-if="checkFreqExists" @click="sortBy('CHECK freq')">CHECK freq <button>▲</button></th>
                <th @click="sortBy('Suit')">Suit <button>▲</button></th>
                <th @click="sortBy('Type')">Type <button>▲</button></th>
                <th @click="sortBy('Connectedness')">Connectedness <button>▲</button></th>
                <th @click="sortBy('Rank1_Type')">Rank1_Type <button>▲</button></th>
                <th @click="sortBy('Rank2_Type')">Rank2_Type <button>▲</button></th>
                <th @click="sortBy('Rank3_Type')">Rank3_Type <button>▲</button></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in sortedData" :key="row.Flop">
                <td class="table-cell">
                  <span v-for="card in row.Flop.split(' ')" :key="card">
                    <span :class="cardColor(card.slice(-1))">{{ cardSymbol(card.slice(-1)) }}</span>{{ card.slice(0, -1) }}
                  </span>
                </td>
                <td class="table-cell"><div>{{ parseFloat(row['OOP Equity']).toFixed(1) }}</div></td>
                <td class="table-cell"><div>{{ parseFloat(row['IP Equity']).toFixed(1) }}</div></td>
                <td v-for="(betSize, index) in filteredBetSizes" :key="index" class="table-cell">
                  <div :class="`bet-bg-${index+1}`" :style="{ width: row[`Bet ${betSize.size}% freq`] + '%' }"></div>
                  <div class="table-text">{{ parseFloat(row[`Bet ${betSize.size}% freq`]).toFixed(1) }}</div>
                </td>
                <td v-if="checkFreqExists" class="table-cell">
                  <div class="check-bg" :style="{ width: row['CHECK freq'] + '%' }"></div>
                  <div class="table-text">{{ parseFloat(row['CHECK freq']).toFixed(1) }}</div>
                </td>
                <td class="table-cell"><div class="table-text">{{ row.Suit }}</div></td>
                <td class="table-cell"><div>{{row.Type}}</div></td>
                <td class="table-cell"><div>{{row.Connectedness}}</div></td>
                <td class="table-cell"><div>{{row.Rank1_Type}}</div></td>
                <td class="table-cell"><div>{{row.Rank2_Type}}</div></td>
                <td class="table-cell"><div>{{row.Rank3_Type}}</div></td>
              </tr>
            </tbody>
            </table>
            <p v-else>No data available</p>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      file: '',
      fileName: 'No file uploaded',
      errorMessage: '',
      showError: false,
      betSizes: [{ size: '', freq: '' }, { size: '', freq: '' }, { size: '', freq: '' }, { size: '', freq: '' }, { size: '', freq: '' }],
      responseData: [],
      sortKey: '',
      sortOrders: {},
    }
  },
  computed: {
    sortedData: function () {
      const sortKey = this.sortKey;
      const order = this.sortOrders[sortKey] || 0;
      let data = [...this.responseData];
      if (sortKey) {
        data = data.sort(function (a, b) {
          a = parseFloat(a[sortKey]);
          b = parseFloat(b[sortKey]);
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return data;
    },
    filteredBetSizes() {
      return this.betSizes.filter(betSize => betSize.size);
    },
    checkFreqExists() {
      return this.responseData.length > 0 && "CHECK freq" in this.responseData[0];
    },
  },
  methods: {
    sortBy: function (key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1 || 1;
    },
    cardColor(suit) {
      switch (suit) {
        case 's':
          return 'text-black';
        case 'h':
          return 'text-red-600';
        case 'd':
          return 'text-blue-600';
        case 'c':
          return 'text-green-600';
        default:
          return '';
      }
    },
    cardSymbol(suit) {
      switch (suit) {
        case 's':
          return '♠';
        case 'h':
          return '♥';
        case 'd':
          return '♦';
        case 'c':
          return '♣';
        default:
          return '';
      }
    },
  handleDrop(e) {
    e.preventDefault();
    this.file = e.dataTransfer.files[0];
    this.fileName = 'Uploaded file: ' + this.file.name;
  },
  submitFile() {
    this.errorMessage = '';
    this.showError = false;
    
    if (!this.file) {
      this.errorMessage = 'No file selected';
      this.showError = true;
      return;
    }

    let formData = new FormData();
    formData.append('file', this.file);
    for (const betSize of this.betSizes) {
      if (betSize.size !== '') {
        formData.append('betSizes', betSize.size);
      }
    }
    axios.post(process.env.VUE_APP_BACKEND_URL + 'process-csv/AggregatedReportVisualizer',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'json'
      }
    ).then(response => {
      this.responseData = response.data;
      this.file = '';
      this.fileName = 'No file uploaded';
      this.$refs.file.value = ''; // <-- add this line to clear the file input
    }).catch(error => {
      if (error.response) {
        this.errorMessage = error.response.data.error || 'An error occurred';
      } else if (error.request) {
        this.errorMessage = 'No response from server';
      } else {
        this.errorMessage = error.message;
      }
      this.showError = true;
    });

    this.file = '';
    this.fileName = 'No file uploaded';
  },
  triggerFileInput() {
    this.$refs.file.click();
  },
  handleFileUpload(){
    this.file = this.$refs.file.files[0];
    this.fileName = 'Uploaded file: ' + this.file.name;
    this.$refs.file.value = null;
    }
  },
  created() {
    ['Flop', 'OOP Equity', 'IP Equity', 'Bet 1% freq', 'Bet 2% freq', 'Bet 3% freq', 'Bet 4% freq', 'Bet 5% freq', 'CHECK freq', 'Suit', 'Type', 'Connectedness', 'Rank1_Type', 'Rank2_Type', 'Rank3_Type'].forEach(key => {
      this.sortOrders[key] = 1;
    });
  }
}
</script>

<style scoped>
table {
  border-collapse: collapse;
}

table th, table td {
  border: 1px solid #ddd; /* 灰色の罫線 */
}

.table-cell {
  font-weight: bold;
  color: black;
  position: relative;
}

.table-cell div {
  text-align: center;
}

.table-text {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.bet-bg-1, .bet-bg-2, .bet-bg-3, .bet-bg-4, .bet-bg-5, .check-bg {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#main-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

#upload-and-bet-size {
  display: flex;
  justify-content: flex-start;
  gap: 50px;
}

#bet-sizes-container {
  width: 250px;
}

#drop-zone {
  width: 300px;
  height: 200px;
  border: 2px dashed #aaa;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.drag-area {
  margin-top: 60px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.process-button-wrapper {
  margin-top: 0px;
}

.process-button {
  margin-top: 0px; 
  background-color: #0275d8;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.error-message {
  margin-top: 10px; 
  color: transparent;
  height: 40px;
  line-height: 0px;
  text-align: center;
}

.error-message-visible {
  color: red;
}

.file-name {
  height: 40px;
  line-height: 40px;
  margin-top: 10px;
}
</style>