<template>
  <div class="container mx-auto p-4 flex flex-col">
    <div class="flex mb-6 justify-between">
      <!-- Preview -->
      <div class="preview w-full mb-4">
          <h3 class="text-xl font-bold mb-3">Preview:</h3>
          <div v-for="row in 13" :key="'row-preview-' + row" class="flex">
              <div 
                  v-for="col in 13" 
                  :key="'cell-preview-' + row + '-' + col" 
                  class="cell relative border border-gray-300 flex items-center justify-center bg-transparent"
                  :style="getCellBackgroundColor(row, col)"
              >
                  <span class="absolute top-0 left-0 text-xs font-bold">
                      {{ getCardCombination(row, col) }}
                  </span>
              </div>
          </div>

          <div class="flex mt-4">
              <div class="flex items-center mr-6">
                  <span class="px-3 py-1 bg-red-600 text-white rounded-l">Raise</span>
                  <span class="px-3 py-1 bg-red-200 text-red-800 rounded-r w-16 text-center font-bold">{{ getActionPercentagePreview('Raise') }}%</span>
              </div>
              <div class="flex items-center mr-6">
                  <span class="px-3 py-1 bg-green-600 text-white rounded-l">Call</span>
                  <span class="px-3 py-1 bg-green-200 text-green-800 rounded-r w-16 text-center font-bold">{{ getActionPercentagePreview('Call') }}%</span>
              </div>
              <div class="flex items-center">
                  <span class="px-3 py-1 bg-blue-600 text-white rounded-l">Fold</span>
                  <span class="px-3 py-1 bg-blue-200 text-blue-800 rounded-r w-16 text-center font-bold">{{ getActionPercentagePreview('Fold') }}%</span>
              </div>
          </div>
      </div>

      <!-- Input Section -->
      <div class="input-section w-full">
          <h3 class="text-xl font-bold mb-3">Range Setting:</h3>

          <div class="flex items-center mb-3">
              <span class="px-3 py-1 bg-red-600 text-white rounded-l w-16 flex-none">Raise</span>
              <input v-model="raiseInput" id="raiseInput" class="input-field border rounded-r p-1 w-full ml-2" type="text" placeholder="Enter values for Raise">
          </div>

          <div class="flex items-center mb-3">
              <span class="px-3 py-1 bg-green-600 text-white rounded-l w-16 flex-none">Call</span>
              <input v-model="callInput" id="callInput" class="input-field border rounded-r p-1 w-full ml-2" type="text" placeholder="Enter values for Call">
          </div>

          <!-- この部分を変更 -->
          <label for="processName">Process Name:</label>
          <input v-model="processName" id="processName" class="refined-input" placeholder="Enter process name...">
          <!-- ボタンの配置を変更 -->
          <div class="flex mb-2"> 
            <button @click="displayPreviewCells" class="preview-btn font-bold bg-gray-200 text-Brack rounded py-2 px-6 block w-24">Preview</button>
          </div>
          <!-- ボタンの配置を変更 -->
          <div class="flex mt-0"> 
            <button @click="() => { displayPreviewCells(); fetchRoundedData(1); }" class="rounding-btn font-bold rounded py-2 px-6">Round 1</button>
            <button @click="() => { displayPreviewCells(); fetchRoundedData(0.5); }" class="rounding-btn font-bold rounded py-2 px-6">Round 1/2</button>
            <button @click="() => { displayPreviewCells(); fetchRoundedData(0.25); }" class="rounding-btn font-bold rounded py-2 px-6">Round 1/4</button>
            <button @click="() => { displayPreviewCells(); fetchClusteredData(); }" class="cluster-btn font-bold rounded py-2 px-6 block w-24">Cluster</button>
          </div>
      </div>
    </div>

    <!-- Results Section -->
    <div class="flex mb-6">
      <!-- Result -->
      <div class="result w-full" ref="resultSection">
          <h3 class="text-xl font-bold mb-3">Result:</h3>
          <!-- 13x13のマス目のコンテナ -->
          <div ref="resultGrid">
            <div v-for="row in 13" :key="'row-result-' + row" class="flex">
              <div 
                v-for="col in 13" 
                :key="'cell-result-' + row + '-' + col" 
                class="cell relative border border-gray-300 flex items-center justify-center bg-transparent"
                :style="getResultCellBackgroundColor(row, col)"
              >
                <span class="absolute top-0 left-0 text-xs font-bold">
                  {{ getCardCombination(row, col) }}
                </span>
              </div>
            </div>
          </div>
            <div class="flex space-x-6 mb-4 mt-6">
                <div class="flex flex-col items-start">
                    <div class="flex items-center">
                        <span class="px-3 py-1 bg-red-600 text-white rounded-l">Raise</span>
                        <span class="px-3 py-1 bg-red-200 text-red-800 rounded-r w-16 text-center font-bold">{{ getActionPercentageResult('Raise') }}%</span>
                    </div>
                    <button @click="copyRangeToClipboard('Raise')" class="mt-2 p-2 bg-gray-600 text-white rounded w-10 flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V6a1 1 0 00-1-1H8z"></path>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 2a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1V3a1 1 0 011-1h4z"></path>
                        </svg>
                    </button>
                </div>
                <div class="flex flex-col items-start">
                    <div class="flex items-center">
                        <span class="px-3 py-1 bg-green-600 text-white rounded-l">Call</span>
                        <span class="px-3 py-1 bg-green-200 text-green-800 rounded-r w-16 text-center font-bold">{{ getActionPercentageResult('Call') }}%</span>
                    </div>
                    <button @click="copyRangeToClipboard('Call')" class="mt-2 p-2 bg-gray-600 text-white rounded w-10 flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V6a1 1 0 00-1-1H8z"></path>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 2a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1V3a1 1 0 011-1h4z"></path>
                        </svg>
                    </button>
                </div>
                <div class="flex flex-col items-start">
                    <div class="flex items-center">
                        <span class="px-3 py-1 bg-blue-600 text-white rounded-l">Fold</span>
                        <span class="px-3 py-1 bg-blue-200 text-blue-800 rounded-r w-16 text-center font-bold">{{ getActionPercentageResult('Fold') }}%</span>
                    </div>
                    <button @click="copyRangeToClipboard('Fold')" class="mt-2 p-2 bg-gray-600 text-white rounded w-10 flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V6a1 1 0 00-1-1H8z"></path>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 2a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1V3a1 1 0 011-1h4z"></path>
                        </svg>
                    </button>
                </div>
            </div>
      </div>

      <!-- Processed Results -->
      <div class="processed-results w-full">
        <div class="flex justify-between items-center mb-3">
          <h3 class="text-xl font-bold">Processed Results:</h3>
          <button @click="exportAsImage" class="bg-green-600 text-white px-4 py-0.5 rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50">
            Export as Image
          </button>
        </div>
        
        <div class="table-scroll-container">
          <table class="w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                <th class="border border-gray-300 px-4 py-2 text-center">Name</th>
                <th class="border border-gray-300 px-4 py-2 text-center">Process</th>
                <th class="border border-gray-300 px-4 py-2 text-center">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="(result, index) in processedResults" 
                :key="'processed-result-' + index"
                @click="selectRow(result, index)"
                :class="{ 'selected-row': selectedRowIndex === index }"
              >
                <td class="border border-gray-300 px-4 py-2 text-center">{{ result.name }}</td>
                <td class="border border-gray-300 px-4 py-2 text-center">{{ result.actionType }}</td>
                <td class="border border-gray-300 px-4 py-2 text-center">{{ result.date }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import html2canvas from 'html2canvas';

export default {
  data() {
    return {
      raiseInput: '',
      callInput: '',
      foldInput: '',
      processName: '',
      processCount: 1,
      processedResults: [],
      selectedRowIndex: null, 
      actions: {
        "Raise": {},
        "Call": {},
        "Fold": {}
      },
      processedActions: {
        "Raise": {},
        "Call": {},
        "Fold": {}
      },
      actionColors: {
        "Raise": "rgba(255, 50, 50, 0.8)", // 明るい赤
        "Call": "rgba(50, 255, 50, 0.7)",  // 明るい緑
        "Fold": "rgba(50, 50, 255, 0.7)"  // 明るい青
      }
    };
  },
  methods: {
    getCardCombination(row, col) {
      const cards = ['A', 'K', 'Q', 'J', 'T', '9', '8', '7', '6', '5', '4', '3', '2'];
      if (row === col) {
        return cards[row - 1] + cards[col - 1];
      } else if (row < col) {
        return cards[row - 1] + cards[col - 1] + 's';
      } else {
        return cards[col - 1] + cards[row - 1] + 'o';
      }
    },
    getCellBackgroundColor(row, col) {
      const combination = this.getCardCombination(row, col);
      let totalFrequency = 0;
      let gradientData = [];

      const actionColors = {
        "Raise": "rgba(255, 50, 50, 0.8)", // 明るい赤
        "Call": "rgba(50, 255, 50, 0.7)",  // 明るい緑
        "Fold": "rgba(50, 50, 255, 0.7)"  // 明るい青
      };

      for (let action in this.actions) {
          if (this.actions[action][combination]) {
              const frequency = this.actions[action][combination];
              totalFrequency += frequency;
              gradientData.push({ action: action, color: actionColors[action], frequency });
          }
      }

      // ここでgradientDataをfrequencyでソート
      gradientData.sort((a, b) => {
        const sortOrder = ["Raise", "Call", "Fold"];
        return sortOrder.indexOf(a.action) - sortOrder.indexOf(b.action);
      });

      // アクションのデータが存在しない場合、背景を透明に設定
      if (gradientData.length === 0) {
        return {
          background: "transparent"
        };
      }

      let gradient = [];
      let accumulatedFrequency = 0;

      gradientData.forEach(data => {
        let start = accumulatedFrequency * 100;
        accumulatedFrequency += data.frequency;
        let end = accumulatedFrequency * 100;
        gradient.push(`${data.color} ${start}%, ${data.color} ${end}%`);
      });

      return {
        background: `linear-gradient(90deg, ${gradient.join(', ')}, transparent ${totalFrequency * 100}%)`
      };
    },
    getResultCellBackgroundColor(row, col) {
      const combination = this.getCardCombination(row, col);
      let totalFrequency = 0;
      let gradientData = [];

      const actionColors = {
        "Raise": "rgba(255, 50, 50, 0.8)", // 明るい赤
        "Call": "rgba(50, 255, 50, 0.7)",  // 明るい緑
        "Fold": "rgba(50, 50, 255, 0.7)"  // 明るい青
      };

    for (let action in this.processedActions) {
        if (this.processedActions[action][combination]) {
            const frequency = this.processedActions[action][combination];
            totalFrequency += frequency;
            gradientData.push({ action: action, color: actionColors[action], frequency });
        }
    }

    // ここでgradientDataをfrequencyでソート
    gradientData.sort((a, b) => {
      const sortOrder = ["Raise", "Call", "Fold"];
      return sortOrder.indexOf(a.action) - sortOrder.indexOf(b.action);
    });

      // アクションのデータが存在しない場合、背景を透明に設定
      if (gradientData.length === 0) {
        return {
          background: "transparent"
        };
      }

      let gradient = [];
      let accumulatedFrequency = 0;

      gradientData.forEach(data => {
        let start = accumulatedFrequency * 100;
        accumulatedFrequency += data.frequency;
        let end = accumulatedFrequency * 100;
        gradient.push(`${data.color} ${start}%, ${data.color} ${end}%`);
      });

      return {
        background: `linear-gradient(90deg, ${gradient.join(', ')}, transparent ${totalFrequency * 100}%)`
      };
    },
    displayPreviewCells() {
        // 全てのアクションデータをリセット
        this.actions = {
            "Raise": {},
            "Call": {},
            "Fold": {}
        };

        // 新しいデータで上書き
        this.actions.Raise = this.parseInput(this.raiseInput);
        this.actions.Call = this.parseInput(this.callInput);

        // 全てのカードの組み合わせを取得
        const allCombinations = this.getAllCardCombinations();
        allCombinations.forEach(combo => {
            const raiseFreq = this.actions.Raise[combo] || 0;
            const callFreq = this.actions.Call[combo] || 0;
            this.actions.Fold[combo] = 1 - raiseFreq - callFreq;
        });
    },
    
    printComboCounts(action) {
      const cards = ['A', 'K', 'Q', 'J', 'T', '9', '8', '7', '6', '5', '4', '3', '2'];
      let comboCounts = {};

      for (let i = 0; i < cards.length; i++) {
        for (let j = i; j < cards.length; j++) {
          const combo = i === j ? cards[i] + cards[j] : (i < j ? cards[i] + cards[j] + 's' : cards[j] + cards[i] + 'o');
          const freq = this.actions[action][combo] || 0;
          const comboMultiplier = combo.endsWith('o') ? 12 : (combo.endsWith('s') ? 4 : 6);  // ここで各ハンドタイプのコンボ数を考慮
          comboCounts[combo] = comboMultiplier * freq;
        }
      }
    },


    parseInput(input) {
        let parsedData = {};

        // Check if the input is defined and non-empty
        if (!input) return parsedData;

        input.split(',').forEach(item => {
            // Split each item by ":"
            const parts = item.split(':');
            const key = parts[0].trim();
            const rawValue = parts.length > 1 ? parseFloat(parts[1].trim()) : 1;
            let value;

            // For 4-char input like AcKc
            if (key.length === 4) {
                const suit1 = key[1];
                const suit2 = key[3];
                const rank1 = key[0];
                const rank2 = key[2];

                let shortHand;
                const rankOrder = "23456789TJQKA";

                if (rank1 === rank2) {
                    // Pair
                    shortHand = rank1 + rank2;
                    value = rawValue / 6;
                } else if (suit1 === suit2) {
                    // Suited
                    shortHand = rank1 + rank2 + 's';
                    value = rawValue / 4;
                } else {
                    // Offsuit
                    // Ensure higher rank is first
                    if (rankOrder.indexOf(rank1) > rankOrder.indexOf(rank2)) {
                        shortHand = rank1 + rank2 + 'o';
                    } else {
                        shortHand = rank2 + rank1 + 'o';
                    }
                    value = rawValue / 12;
                }

                parsedData[shortHand] = (parsedData[shortHand] || 0) + value;
            } else {
                const value = parts.length > 1 ? parseFloat(parts[1].trim()) : 1;

                if (key.length === 2 && key[0] !== key[1]) {
                    parsedData[key + 's'] = value;
                    parsedData[key + 'o'] = value;
                } else {
                    parsedData[key] = value;
                }
            }
        });

        return parsedData;
    },

    checkAndAdjustProcessName() {
      let name = this.processName;
      let count = 1;
      while (this.processedResults.some(result => result.name === name)) {
        name = `${this.processName}#${count}`;
        count++;
      }
      this.processName = name;
    },

    getUniqueProcessName(name) {
        const originalName = name;
        let counter = 1;

        while (this.processedResults.some(result => result.name === name)) {
            name = originalName + `(${counter++})`;
        }

        return name;
    },

    async fetchRoundedData(roundingBase) {
        // 送信するデータにアクションタイプと丸めの基準を追加
        const postData = {
            actionType: "Round",
            roundingBase: roundingBase,
            actions: this.actions
        };

        // 更新されたpostDataをバックエンドに送信
        try {
            const response = await axios.post('https://poker-sandbox-backend-7fcd53c6ff83.herokuapp.com/process-range/range_simplifier', postData);
            this.processedActions = response.data;
            console.log(response.data);
        } catch (error) {
            console.error("Error posting data:", error);
        }

        const uniqueName = this.getUniqueProcessName(this.processName || 'data' + this.processCount++);
        this.processedResults.push({
            name: uniqueName,
            type: 'Round',
            actionType: 'Round',
            date: new Date().toLocaleString(),
            actions: JSON.parse(JSON.stringify(this.actions)),
            processedActions: JSON.parse(JSON.stringify(this.processedActions))
        });

        this.processName = ''; 
    },

    async fetchClusteredData() {
        // 送信するデータにアクションタイプを追加
        const postData = {
            actionType: "Cluster",
            actions: this.actions
        };

        // 更新されたpostDataをバックエンドに送信
        try {
            const response = await axios.post('https://poker-sandbox-backend-7fcd53c6ff83.herokuapp.com/process-range/range_simplifier', postData);
            this.processedActions = response.data;
            console.log(response.data);
        } catch (error) {
            console.error("Error posting data:", error);
        }

        const uniqueName = this.getUniqueProcessName(this.processName || 'data' + this.processCount++);
        this.processedResults.push({
            name: uniqueName,
            type: 'Cluster',
            actionType: 'Cluster',
            date: new Date().toLocaleString(),
            actions: JSON.parse(JSON.stringify(this.actions)),
            processedActions: JSON.parse(JSON.stringify(this.processedActions))
        });

        this.processName = ''; 
    },

    getAllCardCombinations() {
      const cards = ['A', 'K', 'Q', 'J', 'T', '9', '8', '7', '6', '5', '4', '3', '2'];
      let combinations = [];
      for (let i = 0; i < cards.length; i++) {
        for (let j = 0; j < cards.length; j++) {
          if (i === j) {
            combinations.push(cards[i] + cards[j]);
          } else if (i < j) {
            combinations.push(cards[i] + cards[j] + 's');
          } else {
            combinations.push(cards[j] + cards[i] + 'o');
          }
        }
      }
      return combinations;
    },
    getActionPercentagePreview(action) {
      return this.calculateActionPercentage(this.actions, action);
    },
    getActionPercentageResult(action) {
      return this.calculateActionPercentage(this.processedActions, action);
    },
    calculateActionPercentage(inputData, action) {
      const cards = ['A', 'K', 'Q', 'J', 'T', '9', '8', '7', '6', '5', '4', '3', '2'];
      let totalCombosForAction = 0;
      const totalPossibleCombos = 1326;  // 全てのハンドの組み合わせの総数

      for (let i = 0; i < cards.length; i++) {
        for (let j = 0; j < cards.length; j++) {
          const combo = i === j ? cards[i] + cards[j] : (i < j ? cards[i] + cards[j] + 's' : cards[j] + cards[i] + 'o');
          const freq = inputData[action][combo] || 0;
          const comboMultiplier = combo.endsWith('o') ? 12 : (combo.endsWith('s') ? 4 : 6);  // 各ハンドタイプのコンボ数を考慮
          totalCombosForAction += comboMultiplier * freq;
        }
      }

      // アクションの%を計算
      const actionPercentage = (totalCombosForAction / totalPossibleCombos) * 100;

      return actionPercentage.toFixed(1);  // 小数点以下2桁で結果を返す
    },
    loadResult(result) {
      this.actions = result.actions;
      this.processedActions = result.processedActions; // これを追加
    },
    addToProcessedResults() {
      // Processed Results に結果を追加するロジック
      this.processedResults.push({
        name: this.processName || 'data' + this.processCount++,
        type: 'Preview',  // このタイプ名は適切に変更することができます。
        date: new Date().toLocaleString(),
        actions: JSON.parse(JSON.stringify(this.actions)) // 現在のactionsデータの深いコピー
      });
      this.processName = ''; // Process Nameをリセット
    },
    selectRow(result, index) {
      this.selectedRowIndex = index;
      this.loadResult(result);
    },
    async exportAsImage() {
      await this.$nextTick();

      const resultElement = this.$refs.resultGrid;
      const rect = resultElement.getBoundingClientRect();  // この行を追加

      const canvas = await html2canvas(resultElement, {
        useCORS: true,
        x: 0,
        y: 0,
        width: rect.width - 127,
        height: rect.height
      });

      const imgData = canvas.toDataURL("image/png");

      let selectedName;
      if (this.selectedRowIndex !== null && this.processedResults[this.selectedRowIndex]) {
        selectedName = this.processedResults[this.selectedRowIndex].name;
      } else if (this.processedResults.length > 0) {
        selectedName = this.processedResults[this.processedResults.length - 1].name;
      } else {
        selectedName = 'result';
      }

      const a = document.createElement('a');
      a.href = imgData;
      a.download = `${selectedName}.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    copyRangeToClipboard(action) {
        const rangeData = this.processedActions[action];
        if (!rangeData) {
            console.error(`No data found for action: ${action}`);
            return;
        }
        const rangeString = Object.entries(rangeData)
                                  .filter(([, value]) => value > 0)
                                  .map(([key, value]) => `${key}: ${value}`)
                                  .join(', ');

        const textarea = document.createElement('textarea');
        textarea.textContent = rangeString;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    },
  }
}
</script>

<style>
.cell {
  width: 3rem;
  height: 2.5rem;
}
.cell span {
  font-weight: bold;  /* 太字にする */
}
.input-field {
  width: 300%; /* 入力欄を親要素の幅に合わせる */
  padding: 0rem; /* パディングを増やして入力欄を大きくする */
}
/* Round ボタンのスタイル */
.rounding-btn {
    background-color: #6B7280;  /* 灰色 */
    color: white;               /* テキストの色 */
    margin-top: 10px;
    margin-right: 10px;
}

/* Cluster ボタンのスタイル */
.cluster-btn {
    background-color: #3B82F6;  /* 濃いブルー */
    color: white;               /* テキストの色 */
    margin-top: 10px;
    margin-right: 10px;
}

/* ボタンのホバー効果 */
.rounding-btn:hover, .cluster-btn:hover {
    opacity: 0.9;  /* 透明度を少し下げる */
}

.selected-row {
  background-color: #e0e0e0;  /* グレーの背景色 */
}
/* Processed Resultsのテーブルデザインの強化 */
table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

thead {
  background-color: #2D3748; /* 深いグレー */
}

th, td {
  padding: 12px 15px; /* セルのパディング */
  border-bottom: 1px solid #E2E8F0; /* セルのボーダー */
  text-align: center;
}

th {
  color: white; /* テーブルヘッダーのテキスト色 */
}

tbody tr:hover {
  background-color: #63B3ED; /* ホバー時の背景色 */
}

.selected-row {
  background-color: #63B3ED; /* 選択された行の背景色 */
}
.table-scroll-container {
  max-height: calc(2.5rem * 12);  /* 2.5remは各行の高さです。12は表示したい行数です */
  overflow-y: auto; /* 縦方向にスクロール可能にする */
}
.refined-input {
  border: 1px solid #e2e8f0; /* 細い境界線 */
  background-color: #f7fafc; /* 薄い背景色 */
  color: #2d3748; /* テキストの色 */
  padding: 0.5rem 1rem; /* 適切なパディング */
  width: 100%; /* 入力欄を親要素の幅に合わせる */
  margin-bottom: 1rem; /* 下の要素との間隔を設定 */
  border-radius: 0.25rem; /* 境界線の丸み */
}

.refined-input::placeholder {
  color: #a0aec0; /* プレースホルダの色 */
}
h3 {
  position: relative;
  display: inline-block;
}

/* タイトルのスタイリング */
h3 {
  position: relative;
  display: inline-block;
}

h3::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 6px;
  background-color: #2D3748;
}

/* テーブルヘッダーの色の変更 */
thead th {
  background-color: #1A202C; /* より濃いグレー */
}
</style>