import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import RangeConverter from '@/components/RangeConverter.vue';
import AggregatedReportPrep from '@/components/AggregatedReportPrep.vue';
import AggregatedReportVisualizer from '@/components/AggregatedReportVisualizer.vue';
import RangeSimplifier from '@/components/RangeSimplifier.vue';
import PreflopSolutionHU from '@/components/PreflopSolutionHU.vue';
import ArticleComponent from '@/components/ArticleComponent.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/range-converter',
    name: 'RangeConverter',
    component: RangeConverter,
  },
  {
    path: '/aggregated-report-prep',
    name: 'AggregatedReportPrep',
    component: AggregatedReportPrep,
  },
  {
    path: '/AggregatedReportVisualizer',
    name: 'AggregatedReportVisualizer',
    component: AggregatedReportVisualizer,
  },
  {
    path: '/RangeSimplifier',
    name: 'RangeSimplifier',
    component: RangeSimplifier,
  },
  {
    path: '/PreflopSolutionHU',
    name: 'PreflopSolutionHU',
    component: PreflopSolutionHU,
  },
  {
    path: '/ArticleComponent',
    name: 'ArticleComponent',
    component: ArticleComponent,
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

export default router;