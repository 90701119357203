<template>
  <div class="flex flex-col min-h-screen">
    <header class="header"> 
      <router-link to="/" class="flex items-center">
        <!-- 以下が提供されたSVGアイコン -->
        <svg width="40px" height="40px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <rect width="100%" height="100%" fill="#007BFF"/>
          <path fill="white" d="M19.976 13.879a.356.356 0 01-.001-.051c0-.023 0-.046-.005-.07l-1.645-6.58a1 1 0 10-1.94.485L17.72 13H2.28l1.501-6h4.772a1 1 0 100-2H3a1 1 0 00-.97.757l-2 8c-.006.023-.005.046-.005.07 0 .016.001.033-.001.05-.002.02-.007.037-.012.055A.25.25 0 000 14v4.509A1.49 1.49 0 001.491 20h17.018A1.49 1.49 0 0020 18.509V14c0-.024-.006-.046-.012-.068l-.001-.004c-.005-.016-.01-.032-.011-.049z"/>
          <path fill="white" d="M14.239 5l.556-2.294.961.242a1.001 1.001 0 00.488-1.94L12.365.03a1 1 0 00-.488 1.94l.978.247-1.725 7.127a3.042 3.042 0 01-.683-.24c-2.403-1.199-4.759-.065-4.891-.001l-.003.001a1.001 1.001 0 00.894 1.79l.003-.001c.081-.037 1.628-.735 3.103.001a5.251 5.251 0 002.366.549c1.398 0 2.45-.51 2.527-.547l.001-.001a1.002 1.002 0 00-.894-1.79c-.11.049-.223.092-.338.128L13.755 7l.484-2z"/>
        </svg>
        <!-- SVGアイコンの後にタイトルが続く -->
        <button class="header-button ml-4">PokerSandbox</button>
      </router-link>
    </header>
    <main class="flex-grow p-4 pt-8">
      <router-view />
    </main>
    <!-- フッターの追加 -->
    <footer class="footer">
      <a href="https://twitter.com/poker_umeco18" target="_blank" rel="noopener noreferrer" class="social-link">
        <!-- TwitterのSVGアイコンを直接使用 -->
        <svg width="30px" height="30px" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg">
          <path class="twitter-bg" d="M350,400H50c-27.6,0-50-22.4-50-50V50C0,22.4,22.4,0,50,0h300c27.6,0,50,22.4,50,50v300
            C400,377.6,377.6,400,350,400z"/>
          <path class="twitter-icon" d="M153.6,301.6c94.3,0,145.9-78.2,145.9-145.9c0-2.2,0-4.4-0.1-6.6c10-7.2,18.7-16.3,25.6-26.6
            c-9.2,4.1-19.1,6.8-29.5,8.1c10.6-6.3,18.7-16.4,22.6-28.4c-9.9,5.9-20.9,10.1-32.6,12.4c-9.4-10-22.7-16.2-37.4-16.2
            c-28.3,0-51.3,23-51.3,51.3c0,4,0.5,7.9,1.3,11.7c-42.6-2.1-80.4-22.6-105.7-53.6c-4.4,7.6-6.9,16.4-6.9,25.8
            c0,17.8,9.1,33.5,22.8,42.7c-8.4-0.3-16.3-2.6-23.2-6.4c0,0.2,0,0.4,0,0.7c0,24.8,17.7,45.6,41.1,50.3c-4.3,1.2-8.8,1.8-13.5,1.8
            c-3.3,0-6.5-0.3-9.6-0.9c6.5,20.4,25.5,35.2,47.9,35.6c-17.6,13.8-39.7,22-63.7,22c-4.1,0-8.2-0.2-12.2-0.7
            C97.7,293.1,124.7,301.6,153.6,301.6"/>
        </svg>
      </a>
      <span class="contact-text"><strong>Contact via</strong> DM</span>
    </footer>
  </div>
</template>

<style scoped>
.header {
  background-color: #007BFF;
  padding: 0px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.header-button {
  background-color: #007BFF;
  color: #fff;
  font-weight: bold;
  border: none;
  padding: 0px 0px;
  border-radius: 4px;
  font-size: 2.5rem;  /* ロゴ名のフォントサイズを1.8remに変更 */
  transition: background-color 0.2s;
  cursor: pointer;
}
.header-button:hover {
  background-color: #0056b3;
}
/* フッターに関するスタイル */
.footer {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  align-items: center;
  background-color: #333; /* 濃いグレーに変更 */
  padding: 5px 10px;
  border-radius: 5px;
}

.social-link {
  color: inherit;
  text-decoration: none;
}

.twitter-bg {
  fill: #1D9BF0;
}

.twitter-icon {
  fill: #FFFFFF;
}
.contact-text {
  margin-left: 10px;
  font-size: 16px;
  color: #FFF; /* テキストの色を白に変更 */
}
</style>