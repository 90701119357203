export const playLines = {
    playLine1: {
        Raise: "AA,KK,QQ,JJ,TT,99,88,77,66,55,44,33,22,AK,AQ,AJ,AT,A9,A8,A7,A6,A5,A4,A3,A2,KQ,KJ,KT,K9,K8,K7,K6,K5,K4,K3,K2,QJ,QT,Q9,Q8,Q7,Q6,Q5,Q4,Q3,Q2s,JT,J9,J8,J7,J6,J5,J4,J3s,J2s,T9,T8,T7,T6,T5s,T5o:0.995,T4s,T3s,T2s,98,97,96,95s,95o:0.99,94s,93s,92s,87,86,85,84s,83s,82s,76,75,74s,73s,72s:0.995,65,64,63s,62s,54,53s,52s,43s,42s,32s",
        Call: ""
      },
      playLine2: {
        Raise: "AA,KK,QQ,JJ,TT,99,88,77:0.975,66:0.77,55:0.49,44:0.45,33:0.25,AK,AQ,AJ,AT,A9s,A9o:0.94,A8s,A8o:0.415,A7s,A7o:0.25,A6s,A6o:0.055,A5s,A5o:0.09,A4s,A4o:0.11,A3s,A3o:0.085,A2s,KQ,KJs,KJo:0.89,KTs,KTo:0.58,K9s,K9o:0.31,K8s:0.17,K8o:0.31,K7s,K7o:0.29,K6s,K6o:0.14,K5s:0.75,K4s:0.485,QJs,QJo:0.6,QTs,QTo:0.365,Q9s,Q9o:0.355,Q8s:0.895,Q8o:0.265,Q6s:0.83,Q5s:0.31,JTs,JTo:0.335,J9s,J9o:0.375,J8s:0.995,J8o:0.295,J7s:0.3,J6s:0.165,J4s:0.045,J3s:0.09,T9s,T9o:0.31,T8s,T8o:0.415,T7s:0.495,T7o:0.005,T6s:0.41,T4s:0.645,T3s:0.33,98s,98o:0.435,97s,97o:0.465,96s:0.28,95s:0.205,87s,87o:0.375,86s,85s:0.33,84s:0.435,76s,76o:0.19,75s:0.795,74s:0.125,65s,64s:0.78,54s,53s:0.585,43s:0.41",
        Call: "77:0.025,66:0.23,55:0.51,44:0.55,33:0.75,22,A9o:0.06,A8o:0.585,A7o:0.75,A6o:0.945,A5o:0.91,A4o:0.89,A3o:0.915,A2o:0.415,KJo:0.11,KTo:0.42,K9o:0.69,K8s:0.83,K8o:0.69,K7o:0.71,K6o:0.86,K5s:0.25,K4s:0.515,K3s,K2s,QJo:0.4,QTo:0.635,Q9o:0.645,Q8s:0.105,Q8o:0.735,Q7s,Q6s:0.17,Q5s:0.69,Q4s,Q3s,Q2s,JTo:0.665,J9o:0.625,J8s:0.005,J8o:0.705,J7s:0.7,J6s:0.835,J5s,J4s:0.955,J3s:0.91,J2s,T9o:0.69,T8o:0.585,T7s:0.505,T7o:0.06,T6s:0.59,T5s,T4s:0.355,T3s:0.67,T2s,98o:0.565,97o:0.535,96s:0.72,95s:0.795,94s,93s:0.045,87o:0.625,86o:0.005,85s:0.67,84s:0.565,76o:0.595,75s:0.205,74s:0.875,65o:0.21,64s:0.22,63s,54o:0.015,53s:0.415,52s,43s:0.59,42s:0.495"
      },
      playLine3: {
        Raise: "AA,KK,QQ,JJ,TT,99:0.315,88:0.28,77:0.02,66:0.03,AK,AQs,AQo:0.21,AJo:0.325,ATo:0.195,A9o:0.115,A8o:0.385,A7o:0.17,A5o:0.07,KQo:0.185,KJo:0.155,KTo:0.22,K9o:0.175,K5s:0.015,K4s:0.115,K3s:0.255,QJo:0.27,QTo:0.33,Q9o:0.26,Q7s:0.15,Q6s:0.09,Q5s:0.26,Q4s:0.225,Q3s:0.045,JTo:0.18,J9o:0.105,J7s:0.195,J6s:0.27,J5s:0.51,T9o:0.31,T6s:0.58,98o:0.045,85s:0.145,74s:0.22",
        Call: "99:0.685,88:0.72,77:0.98,66:0.97,55,44,33,22,AQo:0.79,AJs,AJo:0.675,ATs,ATo:0.805,A9s,A9o:0.885,A8s,A8o:0.615,A7s,A6s,A5s,A4s,A3s,A2s,KQs,KQo:0.815,KJs,KJo:0.845,KTs,KTo:0.78,K9s,K9o:0.355,K8s,K7s,K6s,K5s:0.985,K4s:0.885,K3s:0.745,K2s,QJs,QJo:0.73,QTs,QTo:0.67,Q9s,Q9o:0.01,Q8s,Q7s:0.85,Q6s:0.91,Q5s:0.74,Q4s:0.775,Q3s:0.945,JTs,JTo:0.82,J9s,J9o:0.195,J8s,J7s:0.805,J6s:0.73,J5s:0.435,T9s,T9o:0.69,T8s,T7s,T6s:0.42,98s,97s,96s,87s,86s,85s:0.855,76s,75s,74s:0.765,65s,64s,54s,53s,43s"
      },
      playLine4: {
        Raise: "AA:0.615,KK,QQ,JJ,TT,99:0.19,88:0.56,55:0.135,44:0.595,33:0.01,AK,AQs,AQo:0.78,A5s:0.65,A4s:0.535,A3s:0.62,A2s:0.42,KJs:0.995",
        Call: "AA:0.385,99:0.81,88:0.44,77,66,55:0.865,44:0.405,33:0.99,AQo:0.22,AJ,AT,A9s,A8s,A7s,A6s,A5s:0.35,A4s:0.465,A3s:0.38,A2s:0.58,KQ,KJs:0.005,KJo,KTs,KTo:0.395,K9s,K8s,K7s,K6s,K5s,K4s,QJ,QTs,QTo:0.175,Q9s,Q8s,Q6s,Q5s,JTs,J9s,J8s,T9s,T8s,T7s,98s,97s,96s:0.77,87s,86s,85s:0.02,76s,75s,65s,64s,54s,53s,43s"
      },
      playLine5: {
        Raise: "",
        Call: "AA,KK,QQ,JJ,TT,99,88,77,66,AK,AQ"
      },
  };