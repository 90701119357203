<template>
  <div class="prose prose-lg mx-auto">
    <h1>Vue.jsのコンポーネントシステム</h1>
    <p>
      Vue.jsのコンポーネントシステムは、再利用可能なUI部品を構築するための強力なオプションを提供します。コンポーネントはVueのインスタンスであり、再利用可能なVueインスタンスのオプションを受け入れる関数です。
    </p>
    <p>コンポーネントは独自のスコープを持つため、親と子のコンポーネントはデータを直接参照することはできません。代わりに、propsを使用してデータを子に渡すことができます。</p>
  </div>
</template>

<script>
export default {
  name: "ArticleSample1"
};
</script>

<style scoped>
/* 必要に応じてスタイルを追加 */
</style>
