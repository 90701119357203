import { createApp } from 'vue';
import App from './App.vue';
import router from './router';  // ルーター設定のインポート
import 'tailwindcss/tailwind.css';
import './styles.css';

// vue-gtagのインポート
import VueGtag from "vue-gtag";

const app = createApp(App)
  .use(router)  // ルーターの適用
  // vue-gtagの設定
  .use(VueGtag, {
    config: { id: "G-L7ZQ14DHGJ" }  // あなたのGoogle Analyticsの測定ID
  });

app.mount('#app');