<template>
  <div class="space-y-4">
    <h2 class="text-2xl">Enter text data for hand ranges in Pio format</h2>
    <textarea v-model="inputData" class="w-full h-24 p-2 border-2 border-gray-300 rounded"></textarea>
    <button @click="processData" class="btn-primary">Process</button>
    <h2 class="text-2xl">Hand ranges data in Monker format</h2>
    <textarea ref="output" v-model="result" class="w-full h-24 p-2 border-2 border-gray-300 rounded" readonly></textarea>
    <button @click="copyToClipboard" class="btn-primary">Copy to clipboard</button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      inputData: '',
      result: ''
    }
  },
  methods: {
    async processData() {
      try {
        const backendUrl = 'https://poker-sandbox-backend-7fcd53c6ff83.herokuapp.com/';
        const response = await axios.post(`${backendUrl}/process`, { data: this.inputData });
        this.result = response.data.result;
      } catch (error) {
        console.error('Error processing data:', error);
        // エラー時の処理を追加することができます
      }
    },
    copyToClipboard() {
      this.$refs.output.select();
      document.execCommand('copy');
    }
  }
}
</script>

<style scoped>
.btn-primary {
  @apply bg-blue-500 text-white font-bold py-2 px-4 rounded w-64 text-center;
}
</style>