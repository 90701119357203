<template>
  <div class="container mx-auto px-4">
    <!-- 記事一覧表示 -->
    <div v-if="!selectedArticle">
      <div v-for="article in articles" :key="article.id" class="my-4 p-4 border rounded shadow-lg hover:shadow-xl transition-shadow duration-300">
        <!-- こちらを修正 -->
        <a @click="selectArticle(article.id)" class="text-2xl font-bold hover:text-blue-600 transition-colors duration-300">{{ article.title }}</a>
        <p class="mt-2 text-gray-600">{{ article.summary }}</p>
      </div>
    </div>

    <!-- 記事詳細表示 -->
    <div v-if="selectedArticle" class="my-4">
      <div class="article-header text-center">
        <!-- Placeholder for article title. Actual title will be in the Article component -->
        <h1 class="text-4xl font-bold mb-2">Range Simplifierについて</h1>
        <div class="border-b-2 border-gray-300 mb-4"></div>
        <!-- Placeholder for meta info like publish date, author etc. -->
        <p class="text-gray-500 mb-6">Published on: 2023/08/28 | Author: umeco18</p>
      </div>
      
      <!-- Article content will come from the Article component -->
      <component :is="selectedArticle"></component>

      <div class="article-footer mt-8">
        <!-- Placeholder for author info, related articles, social share buttons etc. -->
      </div>

      <button @click="backToList" class="mt-4 inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">記事一覧に戻る</button>
    </div>
  </div>
</template>

<script>
import Article1 from './ArticleList/Article1.vue';
import Article2 from './ArticleList/Article2.vue';
import Article3 from './ArticleList/Article3.vue';

export default {
  components: {
    Article1,
    Article2,
    Article3
  },
  data() {
    return {
      articles: [
        { id: 1, title: "Range Simplifierについて", summary: "Range Simplifierの機能説明や使い方について説明します。" },
        { id: 2, title: "Article  2のタイトル", summary: "Article  2の概要です。" },
        { id: 3, title: "Article  3のタイトル", summary: "Article  3の概要です。" }
      ],
      selectedArticle: null
    };
  },
  methods: {
    selectArticle(id) {
      const componentsMap = {
        1: Article1,
        2: Article2,
        3: Article3
      };
      this.selectedArticle = componentsMap[id];
    },
    backToList() {
      this.selectedArticle = null;
    }
  }
};
</script>

<style scoped>
/* 必要に応じてスタイルを追加 */
</style>