<template>
  <div class="container">
    <h1 class="title">Welcome to PokerSandbox. Choose a tool from below!</h1>
    <div class="features-grid">
      <div class="feature-box">
        <button @click="$router.push('/RangeSimplifier')" class="btn-primary">Range Simplifier</button>
        <div class="feature-description">Rounds hand range data that is in a mixed strategy in PioSOLVER format to a pure strategy, 25%, 50%, or 100% mixed strategy data.</div>
      </div>
      <div class="feature-box">
        <button @click="$router.push('/range-converter')" class="btn-primary">Pio▶Monker Converter</button>
        <div class="feature-description">Converts hand range text data in PioSOLVER format into MonkerSolver format data.</div>
      </div>
      <!-- New button for PreflopSolutionHU -->
      <div class="feature-box">
        <button @click="$router.push('/PreflopSolutionHU')" class="btn-primary"> HU Preflop Solution</button>
        <div class="feature-description">Provides Heads-UP preflop solutions.※Eff Stack:100BB、RFI:2.5BB、Rake:5%[1.5BB Cap]</div>
      </div>
      <div class="feature-box">
        <button @click="$router.push('/aggregated-report-prep')" class="btn-primary">Aggregated Report Prep</button>
        <div class="feature-description">Processes aggregated analysis data created with PioSOLVER to make it easier to analyze.</div>
      </div>
      <div class="feature-box">
        <button @click="$router.push('/AggregatedReportVisualizer')" class="btn-primary">Aggregated Report Visualizer</button>
        <div class="feature-description">Processes and visualizes aggregated analysis data created with PioSOLVER, displaying it in the browser.</div>
      </div>
      <!-- New button for Article -->
      <div class="feature-box">
        <button @click="$router.push('/ArticleComponent')" class="btn-primary btn-article">Article</button>
        <div class="feature-description">I will post anything poker related that might be useful.</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2rem;  /* タイトルのフォントサイズを2remに変更 */
  color: #333;
}

.features-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.btn-primary {
  background-color: #007BFF;
  color: #fff;
  font-weight: bold;
  border: none;
  padding: 15px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;  /* ボタンのテキストのフォントサイズを1.2remに変更 */
}

.btn-primary:hover {
  background-color: #0056b3;
}

.feature-box {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: calc(50% - 10px);
  padding: 20px;
  background-color: #fff;
}

.feature-description {
  margin-top: 15px;
  color: #555;
}
.btn-article {
  background-color: #808080; /* グレーの色 */
}

.btn-article:hover {
  background-color: #696969; /* グレーより少し暗い色でホバー時のスタイル */
}
</style>